<template>
  <v-dialog v-model="dialog" max-width="1000" persistent>
    <v-card>
      <v-card-title>
        <span class="headline">Product RFS</span>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="filtered" disable-pagination hide-default-footer>
          <template v-slot:item.no="{ index }">
            {{ index + 1 }}
          </template>
          <template v-slot:item.price="{ item }">
            {{ formatPrice(item.price) }}
          </template>
          <template v-slot:item.total="{ item }">
            {{ formatPrice(item.price * item.qty) }}
          </template>
          <template v-slot:body.append="{}">
            <tr>
              <td colspan="7">TOTAL</td>
              <td style="border: 1px">
                {{ formatPrice(total) }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="close" class="mr-2">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "product-rfs",
  props: {
    dialog: Boolean,
    rfs: Object,
  },
  data() {
    return {
      headers: [
        {
          text: "No",
          value: "no",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part#",
          value: "partNum",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Part Name",
          value: "partName",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Description",
          value: "partDesc",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Qty",
          value: "qty",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/M",
          value: "um",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "U/Price",
          value: "price",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    total() {
      let total = 0;
      this.filtered.forEach(x => {
        total += parseFloat(x.qty) * parseFloat(x.price);
      });
      return total;
    },
    filtered() {
      if (this.rfs && this.rfs.productInternals && this.rfs.productInternals.length > 0) {
        return this.rfs.productInternals.filter(x => {
          if (x.partNumber.charAt(0) !== "9") {
            return x;
          }
        });
      }
      return [];
    },
  },
};
</script>

<style></style>
